import React, { lazy, Suspense } from "react"
import { graphql } from "gatsby"

import { TemplateContextProvider } from 'components/post/TemplateProvider'
import { PostProvider } from "../components/post/postContext"

import SEO from "../components/seo"
import Header from "components/header"
import ImpactArea from "components/sections/impactArea"
import Sections from "components/sections"
import Footer from "components/footer"

const AllVibes = lazy(() => import("components/post/blocks/vibeBlock/allVibes"))

const Index = ({ data }) => {

  const pageData = data.allWpPage.edges[0].node

  return (
    <div>
      <SEO
        lang="en-US"
        title={data.allWpPage.edges[0].node.seo.title}
        data={data.allWpPage.edges[0].node.seo}
      />
      <main>
        <SEO lang="en-US" title={pageData.seo.title} data={pageData.seo} />
        <main>
          <Header />
          <ImpactArea data={pageData} />
          <div className='container'>
            <h2 style={{ textAlign: 'center', paddingTop: '2rem' }}>Today's vibe</h2>
            <Suspense fallback={<div>Loading</div>}>
              <AllVibes
                showVibeOfDay={true}
                showAll={false}
              />
            </Suspense>
          </div>
          {pageData.sections.sections &&
            <Sections data={pageData.sections.sections} />
          }

        </main>
        <Footer />
      </main>
    </div>
  )
}

export default Index

export const vibeOfTheDayQuery = graphql`
  query VibeOfTheDay {
    allWpPage(filter: {slug: {in: "vibe-of-the-day"}}) {
      edges {
        node {
          id
          databaseId
          title
          date
          title
          seo {
            title
            opengraphAuthor
            focuskw
            canonical
            opengraphDescription
            metaRobotsNofollow
            metaDesc
            metaKeywords
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphSiteName
            opengraphTitle
            opengraphType
            opengraphUrl
            twitterTitle
            twitterDescription
          }
          impactArea {
            textColor
            backgroundColor
            backgroundType
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED,
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            heading
            vibeset {
              ... on WpVibeset {
                id
                title
                databaseId
                vibesetDetails {
                  gradientImage {
                    mediaItemUrl
                  }
                  gradientVideo {
                    mediaItemUrl
                  }
                }
              }
            }
          }
          sections {
            fieldGroupName
            sections {
              ... on WpPage_Sections_Sections_HighlightBlock {
                bodyText
                highlights {
                  header
                  subheader
                  link
                  image {
                    localFile {
                      publicURL
                    }
                  }
                }
                heading
                cardPerRow
                fieldGroupName
              }
              ... on WpPage_Sections_Sections_HomeImpactArea {
                bodyText
                fieldGroupName
                heading
                vibesets {
                  ... on WpVibeset {
                    slug
                    title
                    id
                    vibesetDetails {
                      exploreLink
                    }
                  }
                }
                links {
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  linkType
                  formId
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_AppStoreCallout {
                text
                appStoreUrl
                fieldGroupName
                googlePlayUrl
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED,
                        placeholder: BLURRED
                      )
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_CalloutBlock {
                fieldGroupName
                heading
                bodyText
                style
                links {
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  linkType
                  formId
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_CitiesBlock {
                heading
                bodyText
                fieldGroupName
                cities {
                  ... on WpCity {
                    id
                    cityDetails {
                      icon {
                        databaseId
                        localFile {
                          publicURL
                        }
                      }
                      placemarker {
                        latitude
                        longitude
                        zoom
                      }
                      vibes {
                        slug
                        name
                      }
                    }
                    title
                    uri
                  }
                }
                links {
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  linkType
                  formId
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_FeaturedPost {
                fieldGroupName
                automatic
                heading
                featuredPost {
                  ... on WpPost {
                    id
                    databaseId
                    title
                    slug
                    uri
                    date
                    content
                    excerpt
                    featuredImage {
                      node {
                        caption
                        mediaItemUrl
                      }
                    }
                    author {
                      node {
                        name
                      }
                    }
                    categories {
                      nodes {
                        name
                      }
                    }
                    postDetails {
                      authorName
                      authorBio
                      customAuthor
                      vibeset {
                        ... on WpVibeset {
                          id
                          title
                          databaseId
                          vibesetDetails {
                            gradientImage {
                              mediaItemUrl
                            }
                            gradientVideo {
                              mediaItemUrl
                            }
                          }
                        }
                      }
                      city {
                        ... on WpCity {
                          id
                          title
                          uri
                          slug
                          databaseId
                        }
                      }
                    }
                  }
                }
                posts {
                  ... on WpPost {
                    id
                    databaseId
                    title
                    slug
                    uri
                    date
                    content
                    excerpt
                    featuredImage {
                      node {
                        caption
                        mediaItemUrl
                      }
                    }
                    author {
                      node {
                        name
                      }
                    }
                    categories {
                      nodes {
                        name
                      }
                    }
                    postDetails {
                      vibeset {
                        ... on WpVibeset {
                          id
                          title
                          databaseId
                          vibesetDetails {
                            gradientImage {
                              mediaItemUrl
                            }
                            gradientVideo {
                              mediaItemUrl
                            }
                          }
                        }
                      }
                      city {
                        ... on WpCity {
                          id
                          title
                          uri
                          slug
                          databaseId
                        }
                      }
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_SingCardsBlock {
                singCards  {
                  fieldGroupName
                  heading
                  posts {
                    vibes
                    vibeQuery {
                      name
                      slug
                    }
                    type
                    title
                    subcategory
                    placeId
                    placeType
                    geoQuery {
                      latitude
                      longitude
                    }
                    fieldGroupName
                    distanceQuery
                    description
                    city
                    categoryQuery
                  }
                  showAllLink
                  subheading
                  style
                  vibemapSuggests
                }
              }
              ... on WpPage_Sections_Sections_QuotesBlock {
                fieldGroupName
                heading
                quotes {
                  attribution
                  fieldGroupName
                  quote
                }
              }
              ... on WpPage_Sections_Sections_VibeCheckBlock {
                fieldGroupName
                heading
                form
              }
              ... on WpPage_Sections_Sections_ImageBlock {
                fieldGroupName
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_StoryBlock {
                fieldGroupName
                blocks {
                  bodyText
                  style
                  caption
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                        )
                      }
                    }
                  }
                  vibeset {
                    ... on WpVibeset {
                      id
                      vibesetDetails {
                        gradientImage {
                          mediaItemUrl
                        }
                        gradientVideo {
                          mediaItemUrl
                        }
                      }
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_TextWithForm {
                fieldGroupName
                heading
                bodyText
                layout
                form
              }
              ... on WpPage_Sections_Sections_TextColumnsIcon {
                fieldGroupName
                columns {
                  bodyText
                  fieldGroupName
                  heading
                  iconSize
                  icon {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                        )
                      }
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_TextWithImage {
                bodyText
                fieldGroupName
                heading
                layout
                textStyles
                style
                caption
                image {
                  altText
                  caption
                  localFile {
                    publicURL
                  }
                }
                vibeset {
                  ... on WpVibeset {
                    id
                    vibesetDetails {
                      gradientImage {
                        mediaItemUrl
                      }
                      gradientVideo {
                        mediaItemUrl
                      }
                    }
                  }
                }
                links {
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  linkType
                  formId
                  email
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_TextWithImageForm {
                fieldGroupName
                heading
                bodyText
                form
                image {
                  altText
                  caption
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED
                      )

                    }
                  }
                }
                layout
                links {
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  linkType
                  formId
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_TextWithImageCallout {
                fieldGroupName
                heading
                bodyText
                layout
                image {
                  altText
                  caption
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
                links {
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  linkType
                  formId
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
                callout {
                  heading
                  bodyText
                  calloutLinks {
                    externalUrl
                    fieldGroupName
                    forceDownload
                    linkText
                    linkType
                    formId
                    pageLink {
                      ... on WpPage {
                        id
                        uri
                      }
                      ... on WpPost {
                        id
                        uri
                      }
                    }
                    file {
                      id
                      localFile {
                        name
                        ext
                        publicURL
                      }
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_LinkCardsBlock {
                fieldGroupName
                heading
                links {
                  link
                  image {
                    id
                    databaseId
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                        )
                      }
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_VibesetsBlock {
                bodyText
                fieldGroupName
                heading
                vibesets {
                  vibeset {
                    ... on WpVibeset {
                      id
                      title
                      databaseId
                      vibesetDetails {
                        exploreLink
                        tagline
                        description
                        mainVibes
                        gradientImage {
                          mediaItemUrl
                        }
                        gradientVideo {
                          mediaItemUrl
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
